import React from 'react'

import Nav from '../components/Nav';
import Footer from '../components/Footer';

import { graphql } from 'gatsby';
import Hero from '../components/Hero';

import { StaticImage } from 'gatsby-plugin-image';

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';
import Markdown from 'markdown-to-jsx';

export default function Development({data}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    
  const heroImage = data.hero.childImageSharp.gatsbyImageData;
  const {content} = data.file;

    return (
        <div>
            <Nav isActive="About"/>
            <Hero title="Development" image={heroImage}/>

            <ParallaxProvider>
            <section className="py-12 bg-sphinx-background-white-2">
              <div className="container mx-auto max-w-6xl">
                <div className="px-6 pb-6 text-2xl text-sphinx-blue font-montserrat font-medium md:text-3xl">
                  <h1 className="leading-relaxed">{content.sectionOneHeading}</h1>
                  <div className="w-2/6 h-1 bg-sphinx-accent"></div>
                </div>
                
                <div className="grid relative grid-cols-12">

                  <div className="-mb-10 p-4 col-start-3 md:col-start-7 row-start-1 col-span-8 md:col-span-7 z-10">
                  <Parallax className="custom-class" y={isMobile ? [0,0] : ["100px", "-20px"]} disabled={isMobile}>
                    <StaticImage className="shadow-xl " src="../images/developmentProfile.png" alt="Sphinx Development at Villas of Vanston." width={600}/>
                    </Parallax>
                  </div>

                  <div className="col-start-1 row-start-2 md:col-start-1 md:row-start-1 col-span-12 md:col-span-8">
                    <div className="grid grid-cols-8 bg-sphinx-background-white">
                      
                      <div className="col-start-1 md:col-start-1 col-span-full md:col-span-6 p-body p-12 text-base font-sanspro max-w-3xl prose lg:prose-lg">
                        <Markdown>{content.sectionOneBody}</Markdown>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </section>
            </ParallaxProvider>

            <div className="py-6 md:pt-12 md:pb-20 bg-sphinx-background-white-2 bg-opacity-80">
              <div className="px-6 container max-w-6xl mx-auto flex flex-col space-y-9">
                

                <StaticImage src="../images/Properties/cancercenter.jpg" alt="Entrance Of Fiji Townhomes" aspectRatio={3} quality={90}/>
                
                <div>
                  <h1 className="mb-3 p-body text-3xl font-medium">{content.sectionTwoHeading}</h1>
                  <div className="w-2/6 h-1 bg-sphinx-accent"></div>
                </div>

                <div className="space-y-10 lg:space-y-0 lg:inline-flex lg:space-x-8">
                  
                  <div className="flex-1">
                    <h2 className="text-2xl font-montserrat mb-4">SDC projects have involved the following scope of services</h2>
                    <div className="prose lg:prose-lg font-sans">
                      <Markdown>{content.sectionTwoBody}</Markdown>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>

            <div className="grid h-52">
              <StaticImage
                  style={{gridArea: "1/1"}}
                  layout="fullWidth"
                  aspectRatio={2/1}
                  alt="Background Image"
                  src={"../images/about/construction.jpg"}
                  formats={["webp", "avif"]}
              />
              <div
                  style={{
                  gridArea: "1/1",
                  position: "relative",
                  placeItems: "center",
                  display: "grid",
                  }}
                  className="bg-sphinx-accent bg-opacity-40">
                      <div className="text-sphinx-white text-xl font-montserrat font-light lg:text-2xl flex justify-center flex-wrap">
                        <h1 className="text-4xl text-center text-sphinx-white w-full">Learn More About SDC Construction</h1>
                        <a href="/construction" className="text-base text-center mt-5 p-2 max-w-max text-sphinx-white font-montserrat border-sphinx-white border-2 flex items-center hover:bg-sphinx-white hover:text-sphinx-accent hover:shadow-lg">Construction</a>
                      </div>
              </div>
            </div>

            <Footer/>
        </div>
    )
}

export const pageQuery = graphql`
  query DevelopmentPageContent {
    file(relativePath: {eq: "pages/development.yml"}) {
      content: childPagesYaml {
        sectionOneHeading
        sectionOneBody
        sectionTwoHeading
        sectionTwoBody
      }
    }

    hero: file(relativePath: {eq: "Heros/development.JPG"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
      }
    }
  }
`